button.btn-primary {
    padding: 10px 20px;
    font-size: 1em;
    min-width: 80px;
    color: white;
    background-color: #0e8fc9; /* Clear background */
    border: 2px solid #000; /* Black border */
    border-radius: 0; /* Slightly rounded corners for a smoother look */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  
    &.keyline {
      background-color: transparent;
    }
  
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: #0e8fc9;
      border-color: black;
    }
  
    &:disabled:hover {
      color: white !important;
    }
  
    &:hover {
      background-color: #0e6d98; /* Change background on hover */
      border-color: #000;
    }
  
    &.selected {
      color: white;
      background-color: #bada55;
    }
  
    &.hit-button {
      &:hover {
        color: white;
        background-color: #bada55;
      }
  
      &.selected {
        color: white;
        background-color: #bada55;
      }
    }
  
    &.miss-button {
      &:hover {
        color: white;
        background-color: #e85a5a;
      }
  
      &.selected {
        color: white;
        background-color: #e85a5a;
      }
    }
  }