.game-over-modal,
.congrats-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-over-content,
.congrats-content {
  background-color: white;
  padding: 20px;
  /* border-radius: 5px; */
  text-align: center;
}

.game-over-content p,
.congrats-content p {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: black;
}

.congrats-content button {
  padding: 10px 20px;
  font-size: 1em;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  background-color: white;
  color: black;
  padding: 20px;
  /* border-radius: 5px; */
  text-align: center;
}

.confirmation-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.game-over-content button,
.confirmation-content button {
  padding: 10px 20px;
  font-size: 1em;
  margin: 0 10px;
}