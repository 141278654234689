.navbar {
    background: black;
    color: white;
    
    .navbar-text {
      color: white;
    }
  }
  
  .hamburger-icon, .navbar-toggler-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Aligns the lines to the left */
    width: 30px; /* Adjust the width as needed */
  }
  
  span.navbar-toggler-icon {
    color: white;
  }
  
  .hamburger-icon span {
    height: 2px; /* Height of each line */
    width: 20px; /* Full width of the parent */
    background-color: white; /* Line color */
    margin-bottom: 5px; /* Space between lines */
    border-radius: 1px; /* Optional: for rounded lines */
  }
  
  .hamburger-icon span:last-child {
    margin-bottom: 0; /* Removes margin from the last line */
  }