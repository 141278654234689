@import './styles/nav.scss';
@import './styles/buttons.scss';
@import './styles/forms.scss';
@import './styles/popups.scss';
@import './styles/finishingGame.scss';
@import './styles/27Game.scss';

/* Generic styles */
body {
  height: 100vh;
  background: rgb(27,29,57);
  background: linear-gradient(180deg, rgba(27,29,57,1) 14%, rgba(23,56,86,1) 43%, rgba(20,77,109,1) 54%, rgba(10,142,179,1) 70%, rgba(0,212,255,1) 100%);
  background-attachment: fixed;
}

.App {
  text-align: center;
}

/* twenty seven */
.app-container {
  padding: 20px;
  color: white;
}

.game-header {
  margin-bottom: 20px;
}

/* Accordion and Table Container */
.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  display: flex;
  justify-content: center;

  .table-responsive {
    width: 100%;
  }
}