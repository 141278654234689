.toggle-switch {
  p {
    margin-bottom: 5px;
    color: white;
  }
  input[type="checkbox"] {
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 100px;
    height: 50px;
    margin: 0 auto;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 65px;
  }
  .toggle-switch {
    p {
      margin-bottom: 5px;
      color: white;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 100px;
    height: 50px;
    margin: 0 auto;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 65px;
  }
}
