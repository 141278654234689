
.finishes-game {
  .finish-display {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 32px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  
    &.finish-selected {
      font-size: 180px;
      line-height: 180px;
      @media (min-width: 1200px) {
        font-size: 500px; /* Fixed size on larger screens */
        line-height: 500px;
      }
    }
  }
  
  .recommended-finish {
    height: 30px;
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  
    span {
      font-size: 18px;
    }
  }
  
  .Scores {
    min-height: 310px;
  }
}
